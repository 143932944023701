/* eslint-disable object-curly-newline */
import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { getPaymentsPaged } from '@api'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@/@core/utils/useFilters'
import fetchPaged from '@core/utils/fetchPaged'

export default function usePaymentList() {
  const { hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()
  const { t } = useUtils()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  const listTable = ref([])
  const totalListTable = ref(0)
  const tableColumns = computed(() => [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
      show: true,
    },
    { text: t('organizations.organization').toUpperCase(), value: 'group.group_name', show: true },
    { text: t('transactions.payment_date').toUpperCase(), value: 'payment_date', show: true },
    { text: t('transactions.amount').toUpperCase(), value: 'amount', show: true },
    { text: t('transactions.payment_method').toUpperCase(), value: 'payment_method.number', sortable: false, show: true },
    { text: t('users.name').toUpperCase(), value: 'payment_method.owner_name', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasPermission(9),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))
  const filters = ref(null)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const descSort = ref([])
  const loading = ref(false)

  const searchQuery = ref('')
  const dateFrom = ref(null)
  const dateTo = ref(null)
  const statusFilter = ref(null)

  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const maxDate = ref(
    new Date(new Date()).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const minDate = ref(
    new Date(+new Date() - 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const fetchPagedPayments = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    if (statusFilter.value) filterOption.status = statusFilter.value
    if (userData.value && userData.value.role !== 'A') {
      filterOption.group_id = userData.value.group_id
    } else if (configOrganization.value) {
      filterOption.group_id = configOrganization.value
    }
    if (configFacility.value) filterOption.facility_id = configFacility.value
    if (dateFrom.value) filterOption.start_date = dateFrom.value
    if (dateTo.value) filterOption.end_date = dateTo.value
    filters.value = filterOption
    const response = await fetchPaged(getPaymentsPaged, options.value, filterOption)

    listTable.value = response.data
    totalListTable.value = response.total
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.status) statusFilter.value = value.status
      if (value.start_date) dateFrom.value = value.start_date
      if (value.end_date) dateTo.value = value.end_date
    }
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchPagedPayments()
    }, time)
  }

  watch([configFacility], async () => {
    dateTo.value = null
    dateFrom.value = null
    statusFilter.value = null
    searchQuery.value = ''
  })

  watch([statusFilter, configOrganization, configFacility, dateFrom, dateTo], async () => {
    // start loading
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch(500)
  })

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    updateFilter('views-payment-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await getDataBySearch(300)
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveClientAvatarVariant = status => {
    if (status === 'S' || status === 'N') return 'success'
    if (status === 'R' || status === 'Y') return 'warning'

    return 'error'
  }

  return {
    userData,
    listTable,
    computedTableColumns,
    totalListTable,
    searchQuery,
    statusFilter,
    dateFrom,
    dateTo,
    maxDate,
    minDate,
    hasOptionsFilter,
    flag,
    filters,
    options,
    descSort,
    loading,

    configOrganization,
    configFacility,

    // i18n
    t,

    fetchPagedPayments,
    getDataBySearch,
    resolveClientAvatarVariant,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
